import { useNavigate } from 'react-router';
import { useEffect, useRef } from 'react';
import useDehnfugenState from './state/dehnfugen';
import useAPICall from './APICall';

import './dehnfugen.css';

const StartPage = () => {
    const history = useNavigate();
    const inputfeld = useRef();
    const Projekt = useDehnfugenState(state => state.Projekt);
    const setProjekt = useDehnfugenState(state => state.setProjekt)
    const setGruenliste = useDehnfugenState(state => state.setGruenliste)

    var {data,error,loading } = useAPICall("/api/v2/ProductData/GruenArtikel");

    useEffect(() => {
        if (loading==false) {
            setGruenliste(data);
        }
    }, [loading]);

    useEffect(() => {
        if (Projekt != null) {
            history("/project")
        }
    }, [Projekt]);

    function NewProject() {
        var new_proj = {
            Titel: "",
            Projektnummer: "",
            Kunde: "",
            OpportunityNummer: "",
            Revision: -1,
            Position: new Array(0)
        };
        setProjekt(new_proj);
    }

    function LoadProject() {

        //setProjekt(null);
    }

    useEffect(() => {
        // document.getElementById('contentFile'
        inputfeld.current.onchange = function (evt) {
            try {
                let files = evt.target.files;
                if (!files.length) {
                    alert('No file selected!');
                    return;
                }
                let file = files[0];
                let reader = new FileReader();
                const self = this;
                let output;
                reader.onload = (event) => {
                    output = JSON.parse(event.target.result);
                    if (output.Titel != undefined && output.Titel != null) {
                        setProjekt(output);
                    }
                };
                reader.readAsText(file);
            } catch (err) {
                console.error(err);
            }
        }
    }, []);





    return (
        <div className='outter_Box'>
            <div className='inner_Box_small'>
                <h2>Dehnfugenberechnung</h2>
                <fieldset className='top_margin20'>
                    <legend>Neues Projekt</legend>
                    Klicken sie auf den Button um ein neues leeres Projekt zu erzeugen.
                    <div className='center_content'>
                    <button className='pretty_button top_margin15 bottom_margin10 w57' disabled={loading} onClick={NewProject}>Leeres Projekt</button>
                    </div>
                </fieldset>
                <fieldset>
                    <legend>Projekt laden</legend>
                    Laden sie eine Projektdatei hoch um die Arbeit an dieser fortzusetzen.
                    <div className="tooltip">❓
                                        <span className="tooltiptext">Hinweis: Projektdateien der Dehnfugenberechnung sind im .json Format. Beachten sie hierbei, dass nicht alle .json Dateien auch DFB Dateien sind. Sollte sich nach dem Laden einer Datei keine Projektübersicht öffnen, dann war es wahrscheinlich keine DFB Datei.</span>
                                    </div>
                    
                    <div className='center_content'>
                    <input className='top_margin15 bottom_margin10' disabled={loading} ref={inputfeld} id="contentFile" type="file" accept="application/json" />
                    </div>
                </fieldset>
            </div>
        </div>
    );
};
export default StartPage;