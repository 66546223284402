import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as ReactDOMClient from 'react-dom/client';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Loader from "./components/loader/loader";

function ErrorComponent({ error }) {
    return <p>An Error Occurred: {error}</p>;
}


function LoadingComponent() {
    return <Loader text="REFRA Authentication.." />
}

const pca = new PublicClientApplication(window.config.AUTHCONFIGURATION);

const authRequest = {
    scopes: ["openid", "profile"]
};

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);


/*
root.render(<React.StrictMode>
            <App />
</React.StrictMode>);
*/

 root.render(<React.StrictMode>
     <MsalProvider instance={pca}>
         <MsalAuthenticationTemplate
             interactionType={InteractionType.Redirect}
             authenticationRequest={authRequest}
             errorComponent={ErrorComponent}
             loadingComponent={LoadingComponent}>
             <App />
         </MsalAuthenticationTemplate>
     </MsalProvider>
 </React.StrictMode>);

