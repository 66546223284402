import { useEffect, useState } from "react"
import { useMsal} from "@azure/msal-react";


export default function useAPICall(url) {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const {accounts,instance} = useMsal();

    useEffect(() => {
        if (url.substr(url.length - 10) == "/undefined") {
            setLoading(true);
            setData([]);
            setLoading(false);
        }
        else {
            const fetchData = async () => {   
                try {         
                           
                    setLoading(true); 
                    if(accounts.length >0)
                      {                        
                        const apiData = instance.acquireTokenSilent({
                          scopes: [window.config.MESSDATENSCOPE],
                          account: accounts[0]
                        }).then((response) => {                        

                          fetch( window.config.MESSDATENAPIURL + url,{
                            method: "GET",
                            headers: {
                              Authorization: 'Bearer ' + response.accessToken,
                              'Content-Type':'application/json; charset=utf-8;'
                            }} ).then((result)=>
                            {
                             return result.json();
                            }).then((data)=>
                            {
                                 setData(data);
                                 setLoading(false)
                           });
                         });        
                      }
                }catch(err){
                    setError(err)
                    setLoading(false)
                }          
            }
            fetchData();
        }
    }, [url])

    return { data, error, loading }
}
