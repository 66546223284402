import useDehnfugenState from './state/dehnfugen';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import { useMsal } from '@azure/msal-react';

import './dehnfugen.css';

const ProjectPage = () => {
    const history = useNavigate();

    const Projekt = useDehnfugenState(state => state.Projekt);
    const setProjekt = useDehnfugenState(state => state.setProjekt)

    const Position = useDehnfugenState(state => state.Position);
    const setPosition = useDehnfugenState(state => state.setPosition)

    const BufferProjektitel = useDehnfugenState(state => state.BufferProjektitel);
    const setBufferProjektitel = useDehnfugenState(state => state.setBufferProjektitel)
    const BufferProjektnummer = useDehnfugenState(state => state.BufferProjektnummer);
    const setBufferProjektnummer = useDehnfugenState(state => state.setBufferProjektnummer)

    const BufferOpportunityNummer = useDehnfugenState(state => state.BufferOpportunityNummer);
    const setBufferOpportunityNummer = useDehnfugenState(state => state.setBufferOpportunityNummer)
    const BufferKunde = useDehnfugenState(state => state.BufferKunde);
    const setBufferKunde = useDehnfugenState(state => state.setBufferKunde)

    const BufferRevision = useDehnfugenState(state => state.BufferRevision);
    const setBufferRevision = useDehnfugenState(state => state.setBufferRevision)

    const ExportInGerman = useDehnfugenState(state => state.ExportInGerman);
    const setExportInGerman = useDehnfugenState(state => state.setExportInGerman)

    useEffect(() => {
        if (Projekt == null) {
            history("/")
        }
        else {
            setBufferProjektitel(Projekt.Titel);
            setBufferProjektnummer(Projekt.Projektnummer);
            setBufferKunde(Projekt.Kunde);
            setBufferOpportunityNummer(Projekt.OpportunityNummer);
            if (Projekt.Revision > -1) {
                setBufferRevision(Projekt.Revision);
            }
            else {
                setBufferRevision(0);
            }
        }
    }, []);

    useEffect(() => {
        if (Projekt == null) {
            history("/")
        }
    }, [Projekt]);

    useEffect(() => {
        if (Position != null) {
            history("/edit")
        }
    }, [Position]);

    const { instance, accounts } = useMsal();

    function NewPosition() {

        let new_pos = {
            Titel: "",
            PositionNummer: "",
            ZeichnungsNummer: "",
            MaterialQualitaet: "",

            TempRev: "",
            TempIrev: "",
            ProzentRev: "",
            ProzentIrev: "",
            TempMantel: "",
            Laenge: "",

            IsRadial: false,

            QuantitaetFugeA: "",
            QuantitaetFugeB: "",
            QuantitaetFugeC: "",
            FugeA: "",
            FugeB: "",
            FugeC: "",
            MatteA: "",
            MatteB: "",
            MatteC: "",
        };

        let new_index = Projekt.Position.length;

        let temp_proj = Projekt;
        temp_proj.Position.push(new_pos);

        setProjekt(temp_proj);
        setPosition(new_index);
    }



    function getDateiname(dateiendung) {
        dateiendung = "." + dateiendung
        if (Projekt.Projektnummer == "" || Projekt.Projektnummer == undefined || Projekt.Projektnummer == null) {
            return ("Unbenanntes DFB Projekt" + dateiendung);

        }
        if (Projekt.OpportunityNummer == "" || Projekt.OpportunityNummer == undefined || Projekt.OpportunityNummer == null) {
            return (Projekt.Projektnummer + " DFB " + dateiendung);
        }

        return (Projekt.Projektnummer + " - " + Projekt.OpportunityNummer + " DFB" + dateiendung);
    }

    function downloadJSON() {
        let temp_proj = Projekt;
        temp_proj.Revision = temp_proj.Revision + 1;
        setProjekt(temp_proj);

        setBufferRevision(temp_proj.Revision);

        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(Projekt));
        var dlAnchorElem = document.getElementById('downloadAnchorElem');
        dlAnchorElem.setAttribute("href", dataStr);
        dlAnchorElem.setAttribute("download", getDateiname("json"));
        dlAnchorElem.click();
    }

    function EditPos(i) {
        setPosition(i);
    }

    function NewProject() {
        setProjekt(null);
    }

    function changeProjekttitel(value) {
        let temp_proj = Projekt;
        temp_proj.Titel = value;
        setProjekt(temp_proj);

        setBufferProjektitel(value);
    }
    function changeProjektnummer(value) {
        let temp_proj = Projekt;
        temp_proj.Projektnummer = value;
        setProjekt(temp_proj);

        setBufferProjektnummer(value);
    }

    function changeKunde(value) {
        let temp_proj = Projekt;
        temp_proj.Kunde = value;
        setProjekt(temp_proj);

        setBufferKunde(value);
    }

    function changeOpportunityNummer(value) {
        let temp_proj = Projekt;
        temp_proj.OpportunityNummer = value;
        setProjekt(temp_proj);

        setBufferOpportunityNummer(value);
    }

    function ResetRev() {
        let temp_proj = Projekt;
        temp_proj.Revision = -1;
        setProjekt(temp_proj);

        setBufferRevision(0);
    }

    function PDFExport() {
        var doc = new jsPDF();
        var export_user = accounts[0].name;
        export_user = export_user.substring(export_user.indexOf(',') + 2, export_user.indexOf(',') + 3) + ". " + export_user.substring(0, export_user.indexOf(','));

        for (let i = 0; i < Projekt.Position.length; i++) {
            if (i > 0) {
                doc.addPage();
            }
            //////////////////////////////////////210x300 coords//////////////////////////////////////

            doc.setLineWidth(0.7);
            doc.setFontSize(15);
            doc.setFont("helvetica", 'bold');
            doc.text(15, 15, (ExportInGerman == true) ? "Dehnfugenberechnung" : "Expansion Joint Calculation");
            doc.line(15, 16.8, (ExportInGerman == true) ? 73 : 86, 16.8);

            //LEFT HAND TOP
            doc.setFont("helvetica", 'normal');
            doc.setFontSize(10);
            doc.setTextColor(144, 144, 144);
            doc.text((ExportInGerman == true) ? "Kommission Nr.:" : "Commission No.:", 42, 25.5, { align: 'right', });
            doc.text((ExportInGerman == true) ? "Kunde:" : "Customer:", 42, 33, { align: 'right', });
            doc.text((ExportInGerman == true) ? "Projekt:" : "Project:", 42, 40.5, { align: 'right', });
            doc.text((ExportInGerman == true) ? "Ersteller:" : "Issued By:", 42, 48, { align: 'right', });
            doc.text((ExportInGerman == true) ? "Zeichnung Nr.:" : "Drawing No.:", 42, 55.5, { align: 'right', });

            doc.setTextColor(0, 0, 0);
            doc.text(Projekt.Projektnummer.toString(), 43, 25.5);
            doc.text(Projekt.Kunde.toString(), 43, 33);
            doc.text(Projekt.Titel.toString(), 43, 40.5);


            doc.text(export_user, 43, 48);
            doc.text(Projekt.Position[i].ZeichnungsNummer.toString(), 43, 55.5);

            doc.addImage("Refratechnik_Logo.png", 'png', 136.5, 8, 61, 14)


            //RIGHT HAND TOP
            doc.setFont("helvetica", 'normal');
            doc.setFontSize(10);
            doc.setTextColor(144, 144, 144);
            doc.text((ExportInGerman == true) ? "Datum:" : "Date:", 115, 25.5, { align: 'right', });
            doc.text((ExportInGerman == true) ? "Oppy Nr.:" : "Oppy No.:", 115, 55.5, { align: 'right', });
            doc.setTextColor(241, 85, 7);
            doc.text((ExportInGerman == true) ? "Rev.:" : "Rev.:", 115, 48, { align: 'right', });

            doc.setTextColor(0, 0, 0);
            let today = new Date();
            doc.text((ExportInGerman == true) ? (today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear()).toString() : ((today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear()).toString(), 116, 25.5,);
            doc.text((BufferRevision < 0) ? "0" : BufferRevision.toString(), 116, 48,);
            doc.text(Projekt.OpportunityNummer.toString(), 116, 55.5,);


            //---OUTTER BOX---
            //horizontal lines
            doc.setLineWidth(0.7);
            doc.line(15, 63, 195, 63);
            doc.line(15, 102, 195, 102);
            doc.line(15, 196, 195, 196);
            doc.line(15, 267, 195, 267);
            //vertical lines
            doc.line(15, 63 - 0.35, 15, 267 + 0.35);
            doc.line(195, 63 - 0.35, 195, 267 + 0.35);


            //---INNER BOX 1--- (15, 63 | 195, 102)
            doc.setFont("helvetica", 'normal');
            doc.setFontSize(10);
            doc.setTextColor(0, 0, 0);
            doc.text((ExportInGerman == true) ? "Position Nr.:" : "Calculation No.:", 21, 68.5);
            doc.text((ExportInGerman == true) ? "Position/Bereich:" : "Component/Area:", 21, 76);
            doc.text((ExportInGerman == true) ? "Radiale Dehnung (Durchmesser):" : "Radial Expansion Diameter:", 21, 83.5);
            doc.text((ExportInGerman == true) ? "Achsiale Dehnung (Länge/Höhe):" : "Axial Expansion Lenght Resp. Height:", 21.2, 91);
            doc.text((ExportInGerman == true) ? "Materialtemperatur:" : "Material Temperature:", 21, 98.5);

            doc.text((ExportInGerman == true) ? "FF-Auskleidung [mm]:" : "Refr. [mm]:", 125.5, 83.5, { align: 'right', });
            doc.text((ExportInGerman == true) ? "FF-Auskleidung [mm]:" : "Refr. [mm]:", 125.5, 91, { align: 'right', });
            doc.text((ExportInGerman == true) ? "FF-Auskleidung [°C]:" : "Refr. [°C]:", 125.5, 98.5, { align: 'right', });

            doc.text((ExportInGerman == true) ? "Stahlbau [mm]:" : "Steel [mm]:", 170.5, 83.5, { align: 'right', });
            doc.text((ExportInGerman == true) ? "Stahlbau [mm]:" : "Steel [mm]:", 170.5, 91, { align: 'right', });
            doc.text((ExportInGerman == true) ? "Stahlbau [°C]:" : "Steel [°C]:", 170.5, 98.5, { align: 'right', });

            doc.setFillColor(245, 131, 66);
            doc.rect(126.5, 80.5, 17, 4.1, 'F')
            doc.rect(126.5, 88, 17, 4.1, 'F')
            doc.rect(126.5, 95.5, 17, 4.1, 'F')

            doc.rect(171.5, 80.5, 17, 4.1, 'F')
            doc.rect(171.5, 88, 17, 4.1, 'F')
            doc.rect(171.5, 95.5, 17, 4.1, 'F')
            let lenght_value;
            if (Projekt.Position[i].Laenge == "" || Projekt.Position[i].Laenge == "." || Projekt.Position[i].Laenge == "," || Projekt.Position[i].Laenge == "-" || Projekt.Position[i].Laenge == undefined || Projekt.Position[i].Laenge == null) {
                lenght_value = "?";
            }
            else {
                lenght_value = parseFloat(Projekt.Position[i].Laenge.replace(/,/g, '.'));
                lenght_value = lenght_value * 1000;
                lenght_value = Math.trunc(lenght_value);
                lenght_value = lenght_value.toString();
            }

            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);
            doc.text((Projekt.Position[i].IsRadial == true) ? (ExportInGerman == true) ? lenght_value.replace(/\./g, ',') : lenght_value : "-", 135, 83.5, { align: 'center', });
            doc.text((Projekt.Position[i].IsRadial == false) ? (ExportInGerman == true) ? lenght_value.replace(/\./g, ',') : lenght_value : "-", 135, 91, { align: 'center', });
            doc.text(Projekt.Position[i].TempIrev, 135, 98.5, { align: 'center', });



            doc.text((Projekt.Position[i].IsRadial == true) ? (ExportInGerman == true) ? lenght_value.replace(/\./g, ',') : lenght_value : "-", 180, 83.5, { align: 'center', });
            doc.text((Projekt.Position[i].IsRadial == false) ? (ExportInGerman == true) ? lenght_value.replace(/\./g, ',') : lenght_value : "-", 180, 91, { align: 'center', });
            doc.text(Projekt.Position[i].TempMantel, 180, 98.5, { align: 'center', });




            doc.text(Projekt.Position[i].PositionNummer, 60, 68.5);
            doc.text(Projekt.Position[i].Titel, 60, 76);





            //---INNER BOX 2--- (15, 102 | 195, 196)

            doc.setFont("helvetica", 'bold');
            doc.setFontSize(12);
            doc.text(17.25, 107.55, (ExportInGerman == true) ? "Thermische Dehnung" : "Thermal Expansion");

            //---------------------------------------BOX 1
            doc.setFillColor(252, 213, 180);
            doc.rect(150 + (2.5) - 0.15, 130.15, 20, 5.25, 'F');
            doc.setFillColor(200, 200, 200);
            doc.rect(35 + (2.5), 115, 40, 10, 'F');

            //horizontal lines
            doc.setLineWidth(0.3);
            doc.line(35 + (2.5), 110, 150 + (2.5), 110);
            doc.line(35 + (2.5), 115, 170 + (2.5), 115);
            doc.line(35 + (2.5), 125, 170 + (2.5), 125);
            doc.line(35 + (2.5), 130, 170 + (2.5), 130);

            //vertical lines
            doc.line(35 + (2.5), 110 - 0.15, 35 + (2.5), 130 + 0.15);
            doc.line(150 + (2.5), 110 - 0.15, 150 + (2.5), 115); //ENDSTRICH NAME!!
            doc.line(75 + (2.5), 110 - 0.15, 75 + (2.5), 130 + 0.15);
            doc.line(98 + (2.5), 115 - 0.15, 98 + (2.5), 130 + 0.15);
            doc.line(112 + (2.5), 115 - 0.15, 112 + (2.5), 130 + 0.15);
            doc.line(150 + (2.5), 115 - 0.15, 150 + (2.5), 130 + 0.15);
            doc.line(170 + (2.5), 115 - 0.15, 170 + (2.5), 130 + 0.15);

            //big box
            doc.setLineWidth(0.6);
            doc.line(150 + (2.5) - 0.15, 130.15, 170 + (2.5) + 0.15, 130.15);
            doc.line(150 + (2.5) - 0.15, 135.4, 170 + (2.5) + 0.15, 135.4);

            doc.line(150 + (2.5) + 0.15, 130.15 - 0.3, 150 + (2.5) + 0.15, 135.4 + 0.3);
            doc.line(170 + (2.5) - 0.15, 130.15 - 0.3, 170 + (2.5) - 0.15, 135.4 + 0.3);

            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? "Rev. Längenänderung" : "Rev. Linear Change", 55 + (2.5), 128.5, { align: 'center', });

            doc.setFont("helvetica", 'normal');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? "Materialqualität" : "Material Description", 55 + (2.5), 113.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? (Projekt.Position[i].IsRadial == true) ? "Durchmesser" : "Länge" : (Projekt.Position[i].IsRadial == true) ? "Diameter" : "Length", 86.5 + (2.5), 118.5, { align: 'center', });
            doc.text("[mm]", 86.5 + (2.5), 123.5, { align: 'center', });

            doc.text("Temp.", 105 + (2.5), 118.5, { align: 'center', });
            doc.text("[°C]", 105 + (2.5), 123.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Wärmedehnung" : "Thermal Expansion", 131 + (2.5), 118.5, { align: 'center', });
            doc.text("[%]", 131 + (2.5), 123.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Dehnung" : "Expansion", 160 + (2.5), 118.5, { align: 'center', });
            doc.text("[mm]", 160 + (2.5), 123.5, { align: 'center', });




            let ProzentRevCalculated = Projekt.Position[i].ProzentRev == "" || Projekt.Position[i].ProzentRev == null || Projekt.Position[i].ProzentRev == "-" ? "?" : parseFloat(Projekt.Position[i].ProzentRev.replace(/,/g, '.') / Projekt.Position[i].TempRev.replace(/,/g, '.') * Projekt.Position[i].TempIrev.replace(/,/g, '.')).toFixed(4);
            ProzentRevCalculated = parseFloat(ProzentRevCalculated.toString());

            let REVDEHNUNG = (parseFloat(lenght_value) * ProzentRevCalculated / 100).toFixed(2);
            REVDEHNUNG = parseFloat(REVDEHNUNG.toString());

            doc.text(Projekt.Position[i].MaterialQualitaet, 76 + (2.5), 113.5);
            doc.text((ExportInGerman == true) ? REVDEHNUNG.toString().replace('.', ',') : REVDEHNUNG.toString(), 160 + (2.5), 128.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? ProzentRevCalculated.toString().replace('.', ',') : ProzentRevCalculated.toString(), 131 + (2.5), 128.5, { align: 'center', });
            doc.text(Projekt.Position[i].TempIrev.toString(), 105 + (2.5), 128.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? lenght_value.replace('.', ',') : lenght_value, 86.5 + (2.5), 128.5, { align: 'center', });


            doc.text((ExportInGerman == true) ? "Reversible thermale Dehnung [mm]:" : "Thermal expansion without PLC [mm]:", 149 + (2.5), 133.8, { align: 'right', });
            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? REVDEHNUNG.toString().replace('.', ',') : REVDEHNUNG.toString(), 160 + (2.5), 133.8, { align: 'center', });

            //------------------------------------------BOX 2
            doc.setFillColor(252, 213, 180);
            doc.rect(150 + (2.5) - 0.15, 154.15, 20, 5.25, 'F');
            doc.setFillColor(200, 200, 200);
            doc.rect(35 + (2.5), 139, 40, 10, 'F');

            //horizontal lines
            doc.setLineWidth(0.3);
            doc.line(35 + (2.5), 139, 170 + (2.5), 139);
            doc.line(35 + (2.5), 149, 170 + (2.5), 149);
            doc.line(35 + (2.5), 154, 170 + (2.5), 154);

            //vertical lines
            doc.line(35 + (2.5), 139 - 0.15, 35 + (2.5), 154 + 0.15);
            doc.line(75 + (2.5), 139 - 0.15, 75 + (2.5), 154 + 0.15);
            doc.line(98 + (2.5), 139 - 0.15, 98 + (2.5), 154 + 0.15);
            doc.line(112 + (2.5), 139 - 0.15, 112 + (2.5), 154 + 0.15);
            doc.line(150 + (2.5), 139 - 0.15, 150 + (2.5), 154 + 0.15);
            doc.line(170 + (2.5), 139 - 0.15, 170 + (2.5), 154 + 0.15);

            //big box
            doc.setLineWidth(0.6);
            doc.line(150 + (2.5) - 0.15, 154.15, 170 + (2.5) + 0.15, 154.15);
            doc.line(150 + (2.5) - 0.15, 159.4, 170 + (2.5) + 0.15, 159.4);

            doc.line(150 + (2.5) + 0.15, 154.15 - 0.3, 150 + (2.5) + 0.15, 159.4 + 0.3);
            doc.line(170 + (2.5) - 0.15, 154.15 - 0.3, 170 + (2.5) - 0.15, 159.4 + 0.3);

            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? "Irrev. Längenänderung" : "Perm. Linear Change", 55 + (2.5), 152.5, { align: 'center', });

            doc.setFont("helvetica", 'normal');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? (Projekt.Position[i].IsRadial == true) ? "Durchmesser" : "Länge" : (Projekt.Position[i].IsRadial == true) ? "Diameter" : "Length", 86.5 + (2.5), 142.5, { align: 'center', });
            doc.text("[mm]", 86.5 + (2.5), 147.5, { align: 'center', });

            doc.text("Temp.", 105 + (2.5), 142.5, { align: 'center', });
            doc.text("[°C]", 105 + (2.5), 147.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Wärmedehnung" : "Thermal Expansion", 131 + (2.5), 142.5, { align: 'center', });
            doc.text("[%]", 131 + (2.5), 147.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Dehnung" : "Expansion", 160 + (2.5), 142.5, { align: 'center', });
            doc.text("[mm]", 160 + (2.5), 147.5, { align: 'center', });





            let IREVDEHNUNG = (parseFloat(lenght_value) * parseFloat(Projekt.Position[i].ProzentIrev.replace(/,/g, '.')) / 100).toFixed(2);


            IREVDEHNUNG = parseFloat(IREVDEHNUNG.toString());


            doc.text((ExportInGerman == true) ? IREVDEHNUNG.toString().replace('.', ',') : IREVDEHNUNG.toString(), 160 + (2.5), 152.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? Projekt.Position[i].ProzentIrev.replace(/\./g, ',') : Projekt.Position[i].ProzentIrev.replace(/,/g, '.'), 131 + (2.5), 152.5, { align: 'center', });
            doc.text(Projekt.Position[i].TempIrev.toString(), 105 + (2.5), 152.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? lenght_value.replace(/\./g, ',') : lenght_value, 86.5 + (2.5), 152.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Irreversible thermale Dehnung [mm]:" : "PLC [mm]:", 149 + (2.5), 157.8, { align: 'right', });
            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? IREVDEHNUNG.toString().replace('.', ',') : IREVDEHNUNG.toString(), 160 + (2.5), 157.8, { align: 'center', });
            //---------------------------------------------BOX 3
            doc.setFillColor(252, 213, 180);
            doc.rect(150 + (2.5) - 0.15, 178.15, 20, 5.25, 'F');
            doc.setFillColor(200, 200, 200);
            doc.rect(35 + (2.5), 163, 40, 10, 'F');

            //horizontal lines
            doc.setLineWidth(0.3);
            doc.line(35 + (2.5), 163, 170 + (2.5), 163);
            doc.line(35 + (2.5), 173, 170 + (2.5), 173);
            doc.line(35 + (2.5), 178, 170 + (2.5), 178);

            //vertical lines
            doc.line(35 + (2.5), 163 - 0.15, 35 + (2.5), 178 + 0.15);
            doc.line(75 + (2.5), 163 - 0.15, 75 + (2.5), 178 + 0.15);
            doc.line(98 + (2.5), 163 - 0.15, 98 + (2.5), 178 + 0.15);
            doc.line(112 + (2.5), 163 - 0.15, 112 + (2.5), 178 + 0.15);
            doc.line(150 + (2.5), 163 - 0.15, 150 + (2.5), 178 + 0.15);
            doc.line(170 + (2.5), 163 - 0.15, 170 + (2.5), 178 + 0.15);

            //big box
            doc.setLineWidth(0.6);
            doc.line(150 + (2.5) - 0.15, 178.15, 170 + (2.5) + 0.15, 178.15);
            doc.line(150 + (2.5) - 0.15, 183.4, 170 + (2.5) + 0.15, 183.4);

            doc.line(150 + (2.5) + 0.15, 178.15 - 0.3, 150 + (2.5) + 0.15, 183.4 + 0.3);
            doc.line(170 + (2.5) - 0.15, 178.15 - 0.3, 170 + (2.5) - 0.15, 183.4 + 0.3);

            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? "Stahldehnung" : "Steel Change", 55 + (2.5), 176.5, { align: 'center', });

            doc.setFont("helvetica", 'normal');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? (Projekt.Position[i].IsRadial == true) ? "Durchmesser" : "Länge" : (Projekt.Position[i].IsRadial == true) ? "Diameter" : "Length", 86.5 + (2.5), 166.5, { align: 'center', });
            doc.text("[mm]", 86.5 + (2.5), 171.5, { align: 'center', });

            doc.text("Temp.", 105 + (2.5), 166.5, { align: 'center', });
            doc.text("[°C]", 105 + (2.5), 171.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Wärmedehnung" : "Thermal Expansion", 131 + (2.5), 166.5, { align: 'center', });
            doc.text("[%]", 131 + (2.5), 171.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Dehnung" : "Expansion", 160 + (2.5), 166.5, { align: 'center', });
            doc.text("[mm]", 160 + (2.5), 171.5, { align: 'center', });



            let ProzentStahl = Projekt.Position[i].TempMantel == "" || Projekt.Position[i].TempMantel == null || Projekt.Position[i].TempMantel == "-" ? "?" : (Projekt.Position[i].TempMantel / 100000).toFixed(5);
            ProzentStahl = parseFloat(ProzentStahl.toString());

            let STAHLDEHNUNG = (parseFloat(lenght_value) * ProzentStahl).toFixed(2);
            STAHLDEHNUNG = parseFloat(STAHLDEHNUNG.toString());


            doc.text((ExportInGerman == true) ? STAHLDEHNUNG.toString().replace('.', ',') : STAHLDEHNUNG.toString(), 160 + (2.5), 176.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? ProzentStahl.toString().replace('.', ',') : ProzentStahl.toString(), 131 + (2.5), 176.5, { align: 'center', });
            doc.text(Projekt.Position[i].TempMantel.toString(), 105 + (2.5), 176.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? lenght_value.replace(/\./g, ',') : lenght_value, 86.5 + (2.5), 176.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Stahlausdehnung [mm]:" : "Steel expansion [mm]:", 149 + (2.5), 181.8, { align: 'right', });
            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? STAHLDEHNUNG.toString().replace('.', ',') : STAHLDEHNUNG.toString(), 160 + (2.5), 181.8, { align: 'center', });
            //-----------------------------------------total box-------------
            doc.setFillColor(249, 179, 124);
            doc.rect(150 + (2.5) - 0.15, 187.15, 20, 5.25, 'F');
            doc.setLineWidth(0.6);
            doc.line(150 + (2.5) - 0.15, 187.15, 170 + (2.5) + 0.15, 187.15);
            doc.line(150 + (2.5) - 0.15, 192.4, 170 + (2.5) + 0.15, 192.4);

            doc.line(150 + (2.5) + 0.15, 187.15 - 0.3, 150 + (2.5) + 0.15, 192.4 + 0.3);
            doc.line(170 + (2.5), 187.15 - 0.3, 170 + (2.5), 192.4 + 0.3);

            let GESAMTDEHNUNG = REVDEHNUNG + IREVDEHNUNG - STAHLDEHNUNG;
            GESAMTDEHNUNG = parseFloat(GESAMTDEHNUNG.toFixed(2).toString());

            doc.setFont("helvetica", 'bold');
            doc.setFontSize(11);
            doc.text((ExportInGerman == true) ? "Gesamte thermale Ausdehnung [mm]:" : "Resulting thermal expansion [mm]:", 149 + (2.5), 190.8, { align: 'right', });
            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? GESAMTDEHNUNG.toString().replace('.', ',') : GESAMTDEHNUNG.toString(), 160 + (2.5), 190.8, { align: 'center', });

            //---------------------------------------------------------------------------------------------------------------------------------------------
            //---INNER BOX 3--- (15, 196 | 195, 269)

            doc.setFont("helvetica", 'bold');
            doc.setFontSize(12);
            doc.text(17.25, 201.55, (ExportInGerman == true) ? "Dehnfugen" : "Expansion Joints");

            //----------------------------------------BOX 4
            doc.setFillColor(252, 213, 180);
            doc.rect(85 + (2.5), 229.15, 25, 5.25, 'F');
            doc.rect(145 + (2.5), 229.15, 25, 5.25, 'F');

            //horizontal lines
            doc.setLineWidth(0.3);
            doc.line(35 + (2.5), 204, 170 + (2.5), 204);
            doc.line(35 + (2.5), 214, 170 + (2.5), 214);
            doc.line(35 + (2.5), 219, 170 + (2.5), 219);
            doc.line(35 + (2.5), 224, 170 + (2.5), 224);
            doc.line(35 + (2.5), 229, 170 + (2.5), 229);

            //vertical lines
            doc.line(35 + (2.5), 204 - 0.15, 35 + (2.5), 229 + 0.15);
            doc.line(170 + (2.5), 204 - 0.15, 170 + (2.5), 229 + 0.15);

            doc.line(50 + (2.5), 204 - 0.15, 50 + (2.5), 229 + 0.15);
            doc.line(85 + (2.5), 204 - 0.15, 85 + (2.5), 229 + 0.15);
            doc.line(110 + (2.5), 204 - 0.15, 110 + (2.5), 229 + 0.15);
            doc.line(145 + (2.5), 204 - 0.15, 145 + (2.5), 229 + 0.15);

            doc.line(50.6 + (2.5), 204 - 0.15, 50.6 + (2.5), 229 + 0.15);
            doc.line(110.6 + (2.5), 204 - 0.15, 110.6 + (2.5), 229 + 0.15);





            doc.setFont("helvetica", 'normal');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? "Anzahl" : "Quantity", 42.5 + (2.5), 210, { align: 'center', });
            doc.text((ExportInGerman == true) ? "Fugendicke" : "Joint size", 67.65 + (2.5), 207.5, { align: 'center', });
            doc.text("[mm]", 67.65 + (2.5), 212.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? "Gesamtdicke" : "Total size", 97.5 + (2.5), 207.5, { align: 'center', });
            doc.text("[mm]", 97.5 + (2.5), 212.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? "Faserdicke" : "Fiber thickness", 127.65 + (2.5), 207.5, { align: 'center', });
            doc.text("[mm]", 127.65 + (2.5), 212.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? "Gesamtdicke" : "Total thickness", 157.5 + (2.5), 207.5, { align: 'center', });
            doc.text("[mm]", 157.5 + (2.5), 212.5, { align: 'center', });




            doc.text((ExportInGerman == true) ? Projekt.Position[i].QuantitaetFugeA.toString().replace('.', ',') : Projekt.Position[i].QuantitaetFugeA.replace(/,/g, '.').toString(), 42.5 + (2.5), 217.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? Projekt.Position[i].QuantitaetFugeB.toString().replace('.', ',') : Projekt.Position[i].QuantitaetFugeB.replace(/,/g, '.').toString(), 42.5 + (2.5), 222.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? Projekt.Position[i].QuantitaetFugeC.toString().replace('.', ',') : Projekt.Position[i].QuantitaetFugeC.replace(/,/g, '.').toString(), 42.5 + (2.5), 227.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? Projekt.Position[i].FugeA.toString().replace('.', ',') : Projekt.Position[i].FugeA.replace(/,/g, '.').toString(), 67.65 + (2.5), 217.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? Projekt.Position[i].FugeB.toString().replace('.', ',') : Projekt.Position[i].FugeB.replace(/,/g, '.').toString(), 67.65 + (2.5), 222.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? Projekt.Position[i].FugeC.toString().replace('.', ',') : Projekt.Position[i].FugeC.replace(/,/g, '.').toString(), 67.65 + (2.5), 227.5, { align: 'center', });

            let FugeAGesamt = parseFloat((Projekt.Position[i].FugeA.replace(/,/g, '.')) * parseFloat(Projekt.Position[i].QuantitaetFugeA.replace(/,/g, '.')));
            FugeAGesamt = parseFloat(FugeAGesamt.toFixed(2).toString());


            let FugeBGesamt = parseFloat((Projekt.Position[i].FugeB.replace(/,/g, '.')) * parseFloat(Projekt.Position[i].QuantitaetFugeB.replace(/,/g, '.')));
            FugeBGesamt = parseFloat(FugeBGesamt.toFixed(2).toString());

            let FugeCGesamt = parseFloat((Projekt.Position[i].FugeC.replace(/,/g, '.')) * parseFloat(Projekt.Position[i].QuantitaetFugeC.replace(/,/g, '.')));
            FugeCGesamt = parseFloat(FugeCGesamt.toFixed(2).toString());

            if (isNaN(FugeAGesamt)) { FugeAGesamt = "" }
            if (isNaN(FugeBGesamt)) { FugeBGesamt = "" }
            if (isNaN(FugeCGesamt)) { FugeCGesamt = "" }



            doc.text((ExportInGerman == true) ? FugeAGesamt.toString().replace('.', ',') : FugeAGesamt.toString().replace(/,/g, '.').toString(), 97.5 + (2.5), 217.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? FugeBGesamt.toString().replace('.', ',') : FugeBGesamt.toString().replace(/,/g, '.').toString(), 97.5 + (2.5), 222.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? FugeCGesamt.toString().replace('.', ',') : FugeCGesamt.toString().replace(/,/g, '.').toString(), 97.5 + (2.5), 227.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? Projekt.Position[i].MatteA.toString().replace('.', ',') : Projekt.Position[i].MatteA.replace(/,/g, '.').toString(), 127.65 + (2.5), 217.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? Projekt.Position[i].MatteB.toString().replace('.', ',') : Projekt.Position[i].MatteB.replace(/,/g, '.').toString(), 127.65 + (2.5), 222.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? Projekt.Position[i].MatteC.toString().replace('.', ',') : Projekt.Position[i].MatteC.replace(/,/g, '.').toString(), 127.65 + (2.5), 227.5, { align: 'center', });

            let MatteAGesamt = parseFloat((Projekt.Position[i].MatteA.replace(/,/g, '.')) * parseFloat(Projekt.Position[i].QuantitaetFugeA.replace(/,/g, '.')));
            MatteAGesamt = parseFloat(MatteAGesamt.toFixed(2).toString());

            let MatteBGesamt = parseFloat((Projekt.Position[i].MatteB.replace(/,/g, '.')) * parseFloat(Projekt.Position[i].QuantitaetFugeB.replace(/,/g, '.')));
            MatteBGesamt = parseFloat(MatteBGesamt.toFixed(2).toString());

            let MatteCGesamt = parseFloat((Projekt.Position[i].MatteC.replace(/,/g, '.')) * parseFloat(Projekt.Position[i].QuantitaetFugeC.replace(/,/g, '.')));
            MatteCGesamt = parseFloat(MatteCGesamt.toFixed(2).toString());

            if (isNaN(MatteAGesamt)) { MatteAGesamt = "" }
            if (isNaN(MatteBGesamt)) { MatteBGesamt = "" }
            if (isNaN(MatteCGesamt)) { MatteCGesamt = "" }


            doc.text((ExportInGerman == true) ? MatteAGesamt.toString().replace('.', ',') : MatteAGesamt.toString().replace(/,/g, '.').toString(), 157.5 + (2.5), 217.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? MatteBGesamt.toString().replace('.', ',') : MatteBGesamt.toString().replace(/,/g, '.').toString(), 157.5 + (2.5), 222.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? MatteCGesamt.toString().replace('.', ',') : MatteCGesamt.toString().replace(/,/g, '.').toString(), 157.5 + (2.5), 227.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Gesamt [mm]:" : "Total [mm]:", 84 + (2.5), 232.8, { align: 'right', });
            doc.text((ExportInGerman == true) ? "Gesamt [mm]:" : "Total [mm]:", 144 + (2.5), 232.8, { align: 'right', });

            //big boxes
            doc.setLineWidth(0.6);
            doc.line(85 + (2.5), 229.15, 110 + (2.5), 229.15);
            doc.line(85 + (2.5), 234.4, 110 + (2.5), 234.4);

            doc.line(85 + (2.5) + 0.15, 229.15 - 0.3, 85 + (2.5) + 0.15, 234.4 + 0.3);
            doc.line(110 + (2.5) - 0.15, 229.15 - 0.3, 110 + (2.5) - 0.15, 234.4 + 0.3);

            doc.line(145 + (2.5), 229.15, 170 + (2.5), 229.15);
            doc.line(145 + (2.5), 234.4, 170 + (2.5), 234.4);

            doc.line(145 + (2.5) + 0.15, 229.15 - 0.3, 145 + (2.5) + 0.15, 234.4 + 0.3);
            doc.line(170 + (2.5) - 0.15, 229.15 - 0.3, 170 + (2.5) - 0.15, 234.4 + 0.3);

            if ((FugeAGesamt == "")) { FugeAGesamt = 0 }
            if ((FugeBGesamt == "")) { FugeBGesamt = 0 }
            if ((FugeCGesamt == "")) { FugeCGesamt = 0 }

            if ((MatteAGesamt == "")) { MatteAGesamt = 0 }
            if ((MatteBGesamt == "")) { MatteBGesamt = 0 }
            if ((MatteCGesamt == "")) { MatteCGesamt = 0 }

            let FUGEN_TOTAL = parseFloat(FugeAGesamt) + parseFloat(FugeBGesamt) + parseFloat(FugeCGesamt);
            FUGEN_TOTAL = parseFloat(FUGEN_TOTAL.toFixed(2).toString());

            let MATTEN_TOTAL = parseFloat(MatteAGesamt) + parseFloat(MatteBGesamt) + parseFloat(MatteCGesamt);
            MATTEN_TOTAL = parseFloat(MATTEN_TOTAL.toFixed(2).toString());


            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);

            doc.text((ExportInGerman == true) ? FUGEN_TOTAL.toString().replace('.', ',') : FUGEN_TOTAL.toString().replace(/,/g, '.').toString(), 97.5 + (2.5), 232.8, { align: 'center', });
            doc.text((ExportInGerman == true) ? MATTEN_TOTAL.toString().replace('.', ',') : MATTEN_TOTAL.toString().replace(/,/g, '.').toString(), 157.5 + (2.5), 232.8, { align: 'center', });

            //----------------------------------------------BOX 5
            doc.setFillColor(252, 213, 180);
            doc.rect(75 + (2.5) - 0.15, 248.15, 95, 10, 'F');
            doc.setFillColor(200, 200, 200);
            doc.rect(35 + (2.5), 238, 40, 10, 'F');
            doc.setFillColor(249, 179, 124);
            doc.rect(138 + (2.5) - 0.15, 258.15, 32, 5.25, 'F');

            //horizontal lines
            doc.setLineWidth(0.3);
            doc.line(35 + (2.5), 238, 170 + (2.5), 238);
            doc.line(35 + (2.5), 248, 170 + (2.5), 248);
            doc.line(35 + (2.5), 253, 170 + (2.5), 253);
            doc.line(75 + (2.5), 258, 170 + (2.5), 258);

            //vertical lines
            doc.line(35 + (2.5), 238 - 0.15, 35 + (2.5), 253 + 0.15);
            doc.line(75 + (2.5), 238 - 0.15, 75 + (2.5), 258 + 0.15);
            doc.line(105 + (2.5), 238 - 0.15, 105 + (2.5), 258 + 0.15);
            doc.line(138 + (2.5), 238 - 0.15, 138 + (2.5), 253 + 0.15);
            doc.line(170 + (2.5), 238 - 0.15, 170 + (2.5), 258 + 0.15);




            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? "Gesamte Fugenstärke" : "Total Expansion Joint", 55 + (2.5), 251.5, { align: 'center', });
            doc.setFont("helvetica", 'normal');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? "Gesamte Faser-" : "Total fiber", 90 + (2.5), 241.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? "dicke [mm]" : "thick. [mm]", 90 + (2.5), 246.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Gesamte Fugen-" : "Total Expansion", 121.5 + (2.5), 241.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? "dicke [mm]" : "Joint [mm]", 121.5 + (2.5), 246.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? "Ges. thermale" : "Res. thermal", 154 + (2.5), 241.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? "Ausdehnung [mm]" : "expansion [mm]", 154 + (2.5), 246.5, { align: 'center', });

            let FUGE_MINUS_RECHNUNG = FUGEN_TOTAL - GESAMTDEHNUNG;
            FUGE_MINUS_RECHNUNG = parseFloat(FUGE_MINUS_RECHNUNG.toFixed(2).toString());

            doc.text((ExportInGerman == true) ? MATTEN_TOTAL.toString().replace('.', ',') : MATTEN_TOTAL.toString().replace(/,/g, '.').toString(), 90 + (2.5), 251.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? MATTEN_TOTAL.toString().replace('.', ',') : MATTEN_TOTAL.toString().replace(/,/g, '.').toString(), 90 + (2.5), 256.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? FUGEN_TOTAL.toString().replace('.', ',') : FUGEN_TOTAL.toString().replace(/,/g, '.').toString(), 121.5 + (2.5), 251.5, { align: 'center', });
            doc.text((ExportInGerman == true) ? GESAMTDEHNUNG.toString().replace('.', ',') : GESAMTDEHNUNG.toString(), 154 + (2.5), 251.5, { align: 'center', });

            doc.text((ExportInGerman == true) ? FUGE_MINUS_RECHNUNG.toString().replace('.', ',') : FUGE_MINUS_RECHNUNG.toString(), 137.5 + (2.5), 256.5, { align: 'center', });

            let KOMPRESSION_PROZENT = 100 - (FUGE_MINUS_RECHNUNG / (MATTEN_TOTAL / 100));
            KOMPRESSION_PROZENT = parseFloat(KOMPRESSION_PROZENT.toFixed(2).toString());

            doc.setFont("helvetica", 'bold');
            doc.setFontSize(10);
            doc.text((ExportInGerman == true) ? KOMPRESSION_PROZENT.toString().replace('.', ',') : KOMPRESSION_PROZENT.toString(), 154 + (2.5), 261.8, { align: 'center', });

            doc.setFont("helvetica", 'bold');
            doc.setFontSize(11);
            doc.text((ExportInGerman == true) ? "Kompression [%]:" : "Compression [%]:", 137 + (2.5), 261.8, { align: 'right', });

            //big box
            doc.setLineWidth(0.6);
            doc.line(138 + (2.5), 258.15, 170 + (2.5), 258.15);
            doc.line(138 + (2.5), 263.4, 170 + (2.5), 263.4);

            doc.line(138 + (2.5) + 0.15, 258.15 - 0.3, 138 + (2.5) + 0.15, 263.4 + 0.3);
            doc.line(170 + (2.5) - 0.15, 258.15 - 0.3, 170 + (2.5) - 0.15, 263.4 + 0.3);




            //---FOOTER--- 

            doc.setFont("helvetica", 'normal');
            doc.setFontSize(8);
            doc.text(15, 276.75, (ExportInGerman == true) ? "Temperaturen sind aus den Wärmedurchgangsberechnungen entnommen." : "Temperatures are taken from heat transfer calculations.");
            doc.text(15, 280, (ExportInGerman == true) ? "Die Berechnungen basieren zum Teil auf empirisch ermittelten Annahmen, die zu einer" : "The calculations are partially based on empirically determined assumptions, which can lead");
            doc.text(15, 283.25, (ExportInGerman == true) ? "Abweichung der Rechenwerte von den späteren Betriebsbedingungen führen können." : "to a deviation of the calculated values from the later operating conditions.");

            doc.setFontSize(12);
            doc.text((ExportInGerman == true) ? "Seite " + (i + 1) + " von " + Projekt.Position.length : "Page " + (i + 1) + " of " + Projekt.Position.length, 195, 283, { align: 'right', });

        }


        doc.setProperties({ title: getDateiname("pdf") });
        if (navigator.userAgent.indexOf("Firefox") > 0) {
            doc.output('dataurlnewwindow');
        }
        else {
            doc.output('save', getDateiname("pdf"));
        }



    }


    function setExportToGerman() {
        setExportInGerman(true);
    }
    function setExportToEnglish() {
        setExportInGerman(false);
    }


    // const PositionsDisplay = Projekt.Position.map((position, i) => {
    //     return <IonSelectOption key={position.Titel + i} value={position.Titel}>{position.Titel}</IonSelectOption>;
    // });

    const PositionsDisplay = (Projekt != null) ? Projekt.Position.map((position, i) => {
        return <tr key={position.Titel + i}>
            <td className='align_center'>{position.PositionNummer}</td>
            <td className='align_left'> {position.Titel}</td>
            <td className='align_center edit_button_td h100'><button className='edit_button' onClick={() => EditPos(i)}>EDIT ✎</button></td>
        </tr>;
    }) : [];

    return (
        <div className='outter_Box'>
            <div className='inner_Box'>
                <h2>Projektübersicht</h2>
                <a id="downloadAnchorElem" className='non_disp'></a>
                <table className='w100'>
                    <tbody>
                        <tr>
                            <td className='w33 '>
                                <fieldset className='h140px'>
                                    <legend>Revision</legend>
                                    <table className='w100 align_center'>
                                        <tbody>
                                            <tr>
                                                <th>Aktuelle Revision
                                                    <div className="tooltip">❓
                                                        <span className="tooltiptext">Die angezeigte Revision stellt die Revision des letzten Speicherstandes dar. Das Zurücksetzen der Revision hat erst beim nächsten Speichern Effekt.</span>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td><b className='font_xxl'>{BufferRevision}</b></td>
                                            </tr>
                                            <tr>
                                                <td><button className='rev_button_margin w75 pretty_button' onClick={ResetRev}>Rev. zurücksetzen</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                            </td>
                            <td className='w33'>
                                <fieldset className='h140px'>
                                    <legend>PDF-Export</legend>
                                    <b>Exportsprache:</b>

                                    <div>
                                        <input type="radio" id="huey" name="drone" value="ger" checked={ExportInGerman} onChange={setExportToGerman} />
                                        <label htmlFor="huey">Deutsch</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="dewey" name="drone" value="eng" checked={!ExportInGerman} onChange={setExportToEnglish} />
                                        <label htmlFor="dewey">Englisch</label>
                                    </div>
                                    <div className='align_center w100'>
                                        <button className='export_button_margin w75 pretty_button' disabled={Projekt == null ? false : Projekt.Position.length == 0} onClick={PDFExport}>Export</button>
                                    </div>
                                </fieldset>
                            </td>
                            <td className='w33'>
                                <fieldset className='h140px'>
                                    <legend>Aktionen</legend>
                                    <table className='align_center w100'>
                                        <tbody>
                                            <tr>
                                                <td className='w100'>
                                                    <button className='margin10 w75  pretty_button' onClick={NewProject}>Neues Projekt</button>
                                                </td>
                                            </tr>
                                            <tr className='h17px' />

                                            <tr>
                                                <td className='w100'>
                                                    <button className='margin10 w75 pretty_button' onClick={downloadJSON}>Projekt speichern</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                            </td>
                        </tr>
                    </tbody>
                </table>


                <fieldset>
                    <legend>Projektinformationen</legend>
                    <table className='w100 align_center'>
                        <tbody className='w100'>
                            <tr className='w100'>
                                <th className='w66' >Projektname</th>
                                <th className='w33'>Angebots-/Auftragsnummer</th>
                            </tr>
                            <tr className='w100'>
                                <td className='w66'><input className='w90' value={BufferProjektitel} onChange={(e) => { changeProjekttitel(e.target.value) }} /></td>
                                <td className='w33'><input className='w80 center_text' value={BufferProjektnummer} onChange={(e) => { changeProjektnummer(e.target.value) }} /></td>
                            </tr>
                        </tbody>
                    </table>

                    <table className='w100 align_center'>
                        <tbody className='w100'>
                            <tr className='w100'>
                                <th className='w66'>Kunde</th>
                                <th className='w33'>Opportunity Nr.</th>
                            </tr>
                            <tr className='w100'>
                                <td className='w66'><input className='w90' value={BufferKunde} onChange={(e) => { changeKunde(e.target.value) }} /></td>
                                <td className='w33'><input className='w80 center_text' value={BufferOpportunityNummer} onChange={(e) => { changeOpportunityNummer(e.target.value) }} /></td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>


                <fieldset className='w900px'>
                    <legend>Positionen</legend>
                    <div className='w100 align_right'>
                        <button className='margin10 w28 pretty_button' onClick={NewPosition}>Neue Position</button>
                    </div>
                    <table className='w100 position_table'>
                        <tbody className='w100'>
                            <tr className='w100'>
                                <th className='w15 color_orange_medium align_center'>Position</th>
                                <th className='w75 color_orange_medium align_center'>Titel</th>
                                <th className='w10 color_orange_medium align_center'>Editieren</th>
                            </tr>
                            {PositionsDisplay}
                        </tbody>
                    </table>
                </fieldset>









            </div>

        </div>
    );
};
export default ProjectPage;