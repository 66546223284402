import { useNavigate } from 'react-router';
import useDehnfugenState from './state/dehnfugen';
import { useEffect, useRef } from 'react';

import './dehnfugen.css';

const EditPage = () => {
    const history = useNavigate();

    const Projekt = useDehnfugenState(state => state.Projekt);
    const setProjekt = useDehnfugenState(state => state.setProjekt)

    const Position = useDehnfugenState(state => state.Position);
    const setPosition = useDehnfugenState(state => state.setPosition)

    const BufferTitel = useDehnfugenState(state => state.BufferTitel);
    const setBufferTitel = useDehnfugenState(state => state.setBufferTitel)
    const BufferPositionNummer = useDehnfugenState(state => state.BufferPositionNummer);
    const setBufferPositionNummer = useDehnfugenState(state => state.setBufferPositionNummer)
    const BufferZeichnungsNummer = useDehnfugenState(state => state.BufferZeichnungsNummer);
    const setBufferZeichnungsNummer = useDehnfugenState(state => state.setBufferZeichnungsNummer)
    const BufferMaterialQualitaet = useDehnfugenState(state => state.BufferMaterialQualitaet);
    const setBufferMaterialQualitaet = useDehnfugenState(state => state.setBufferMaterialQualitaet)
    const BufferTempRev = useDehnfugenState(state => state.BufferTempRev);
    const setBufferTempRev = useDehnfugenState(state => state.setBufferTempRev)
    const BufferTempIrev = useDehnfugenState(state => state.BufferTempIrev);
    const setBufferTempIrev = useDehnfugenState(state => state.setBufferTempIrev)
    const BufferProzentRev = useDehnfugenState(state => state.BufferProzentRev);
    const setBufferProzentRev = useDehnfugenState(state => state.setBufferProzentRev)
    const BufferProzentIrev = useDehnfugenState(state => state.BufferProzentIrev);
    const setBufferProzentIrev = useDehnfugenState(state => state.setBufferProzentIrev)
    const BufferTempMantel = useDehnfugenState(state => state.BufferTempMantel);
    const setBufferTempMantel = useDehnfugenState(state => state.setBufferTempMantel)
    const BufferLaenge = useDehnfugenState(state => state.BufferLaenge);
    const setBufferLaenge = useDehnfugenState(state => state.setBufferLaenge)
    const BufferIsRadial = useDehnfugenState(state => state.BufferIsRadial);
    const setBufferIsRadial = useDehnfugenState(state => state.setBufferIsRadial)
    const BufferQuantitaetFugeA = useDehnfugenState(state => state.BufferQuantitaetFugeA);
    const setBufferQuantitaetFugeA = useDehnfugenState(state => state.setBufferQuantitaetFugeA)
    const BufferQuantitaetFugeB = useDehnfugenState(state => state.BufferQuantitaetFugeB);
    const setBufferQuantitaetFugeB = useDehnfugenState(state => state.setBufferQuantitaetFugeB)
    const BufferQuantitaetFugeC = useDehnfugenState(state => state.BufferQuantitaetFugeC);
    const setBufferQuantitaetFugeC = useDehnfugenState(state => state.setBufferQuantitaetFugeC)
    const BufferFugeA = useDehnfugenState(state => state.BufferFugeA);
    const setBufferFugeA = useDehnfugenState(state => state.setBufferFugeA)
    const BufferFugeB = useDehnfugenState(state => state.BufferFugeB);
    const setBufferFugeB = useDehnfugenState(state => state.setBufferFugeB)
    const BufferFugeC = useDehnfugenState(state => state.BufferFugeC);
    const setBufferFugeC = useDehnfugenState(state => state.setBufferFugeC)
    const BufferMatteA = useDehnfugenState(state => state.BufferMatteA);
    const setBufferMatteA = useDehnfugenState(state => state.setBufferMatteA)
    const BufferMatteB = useDehnfugenState(state => state.BufferMatteB);
    const setBufferMatteB = useDehnfugenState(state => state.setBufferMatteB)
    const BufferMatteC = useDehnfugenState(state => state.BufferMatteC);
    const setBufferMatteC = useDehnfugenState(state => state.setBufferMatteC)


    useEffect(() => {
        if (Position == null) {
            history("/project")
        }
    }, [Position]);

    useEffect(() => {
        if (BufferTitel == null) {
            setBufferTitel(Projekt.Position[Position].Titel);
            setBufferPositionNummer(Projekt.Position[Position].PositionNummer);
            setBufferMaterialQualitaet(Projekt.Position[Position].MaterialQualitaet);
            setBufferTempRev(Projekt.Position[Position].TempRev);
            setBufferTempIrev(Projekt.Position[Position].TempIrev);
            setBufferProzentRev(Projekt.Position[Position].ProzentRev);
            setBufferProzentIrev(Projekt.Position[Position].ProzentIrev);
            setBufferTempMantel(Projekt.Position[Position].TempMantel);
            setBufferLaenge(Projekt.Position[Position].Laenge);
            setBufferQuantitaetFugeA(Projekt.Position[Position].QuantitaetFugeA);
            setBufferQuantitaetFugeB(Projekt.Position[Position].QuantitaetFugeB);
            setBufferQuantitaetFugeC(Projekt.Position[Position].QuantitaetFugeC);
            setBufferFugeA(Projekt.Position[Position].FugeA);
            setBufferFugeB(Projekt.Position[Position].FugeB);
            setBufferFugeC(Projekt.Position[Position].FugeC);
            setBufferMatteA(Projekt.Position[Position].MatteA);
            setBufferMatteB(Projekt.Position[Position].MatteB);
            setBufferMatteC(Projekt.Position[Position].MatteC);
            setBufferZeichnungsNummer(Projekt.Position[Position].ZeichnungsNummer);
            setBufferIsRadial(Projekt.Position[Position].IsRadial);
        }
    }, []);


    function DeletePosition() {
        let temp_proj = Projekt;
        temp_proj.Position.splice(Position, 1);
        setProjekt(temp_proj);
        setPosition(null);
    }

    function ToLoadData() {
        history("/edit/loaddata");
    }

    function BackToProjectPage() {
        let temp_proj = Projekt;

        temp_proj.Position[Position].Titel = BufferTitel;
        temp_proj.Position[Position].PositionNummer = BufferPositionNummer;
        temp_proj.Position[Position].MaterialQualitaet = BufferMaterialQualitaet;
        temp_proj.Position[Position].TempRev = BufferTempRev;
        temp_proj.Position[Position].ZeichnungsNummer = BufferZeichnungsNummer;
        temp_proj.Position[Position].TempIrev = BufferTempIrev;
        temp_proj.Position[Position].ProzentRev = BufferProzentRev;
        temp_proj.Position[Position].ProzentIrev = BufferProzentIrev;
        temp_proj.Position[Position].TempMantel = BufferTempMantel;
        temp_proj.Position[Position].Laenge = BufferLaenge;
        temp_proj.Position[Position].QuantitaetFugeA = BufferQuantitaetFugeA;
        temp_proj.Position[Position].QuantitaetFugeB = BufferQuantitaetFugeB;
        temp_proj.Position[Position].QuantitaetFugeC = BufferQuantitaetFugeC;
        temp_proj.Position[Position].FugeA = BufferFugeA;
        temp_proj.Position[Position].FugeB = BufferFugeB;
        temp_proj.Position[Position].FugeC = BufferFugeC;
        temp_proj.Position[Position].MatteA = BufferMatteA;
        temp_proj.Position[Position].MatteB = BufferMatteB;
        temp_proj.Position[Position].MatteC = BufferMatteC;
        temp_proj.Position[Position].IsRadial = BufferIsRadial;

        temp_proj.Position.sort((a, b) => a.PositionNummer.toLowerCase() > b.PositionNummer.toLowerCase() ? 1 : -1);
        setProjekt(temp_proj);
        setBufferTitel(null);
        setPosition(null);
    }

    function changeBufferTempRev(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferTempRev(val);
        }
    }

    function changeBufferTempIrev(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferTempIrev(val);
        }
    }
    function changeBufferProzentRev(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false || val == "-") {
            setBufferProzentRev(val);
        }
    }
    function changeBufferProzentIrev(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false || val == "-") {
            setBufferProzentIrev(val);
        }
    }
    function changeBufferTempMantel(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferTempMantel(val);
        }
    }
    function changeBufferLaenge(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferLaenge(val);
        }
    }

    function changeBufferQuantitaetFugeA(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferQuantitaetFugeA(val);
        }
    }
    function changeBufferQuantitaetFugeB(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferQuantitaetFugeB(val);
        }
    }
    function changeBufferQuantitaetFugeC(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferQuantitaetFugeC(val);
        }
    }


    function changeBufferFugeA(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferFugeA(val);
        }
    }
    function changeBufferFugeB(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferFugeB(val);
        }
    }
    function changeBufferFugeC(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferFugeC(val);
        }
    }
    function changeBufferMatteA(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferMatteA(val);
        }
    }
    function changeBufferMatteB(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferMatteB(val);
        }
    }
    function changeBufferMatteC(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferMatteC(val);
        }
    }


    function GesamtMatteCalc() {
        let qa = 0;
        let qb = 0;
        let qc = 0;
        let va = 0;
        let vb = 0;
        let vc = 0;
        if (BufferMatteA != "") {
            va = parseFloat(BufferMatteA.replace(/,/g, '.'));
        }
        if (BufferMatteB != "") {
            vb = parseFloat(BufferMatteB.replace(/,/g, '.'));
        }
        if (BufferMatteC != "") {
            vc = parseFloat(BufferMatteC.replace(/,/g, '.'));
        }

        if (BufferQuantitaetFugeA != "") {
            qa = parseFloat(BufferQuantitaetFugeA.replace(/,/g, '.'));
        }
        if (BufferQuantitaetFugeB != "") {
            qb = parseFloat(BufferQuantitaetFugeB.replace(/,/g, '.'));
        }
        if (BufferQuantitaetFugeC != "") {
            qc = parseFloat(BufferQuantitaetFugeC.replace(/,/g, '.'));
        }

        let ret = qa * va + qb * vb + qc * vc;
        ret = ret.toFixed(2);
        ret = ret.toString();
        ret = ret.replace(/\./g, ',')
        return ret;
    }

    function GesamtFugeCalc() {
        let qa = 0;
        let qb = 0;
        let qc = 0;
        let va = 0;
        let vb = 0;
        let vc = 0;
        if (BufferFugeA != "") {
            va = parseFloat(BufferFugeA.replace(/,/g, '.'));
        }
        if (BufferFugeB != "") {
            vb = parseFloat(BufferFugeB.replace(/,/g, '.'));
        }
        if (BufferFugeC != "") {
            vc = parseFloat(BufferFugeC.replace(/,/g, '.'));
        }

        if (BufferQuantitaetFugeA != "") {
            qa = parseFloat(BufferQuantitaetFugeA.replace(/,/g, '.'));
        }
        if (BufferQuantitaetFugeB != "") {
            qb = parseFloat(BufferQuantitaetFugeB.replace(/,/g, '.'));
        }
        if (BufferQuantitaetFugeC != "") {
            qc = parseFloat(BufferQuantitaetFugeC.replace(/,/g, '.'));
        }

        let ret = qa * va + qb * vb + qc * vc;
        ret = ret.toFixed(2);
        ret = ret.toString();
        ret = ret.replace(/\./g, ',')
        return ret;
    }
    const Gesamtdehnung = (BufferProzentRev == "-" || BufferProzentIrev == "-" || BufferProzentRev == "" || BufferProzentIrev == "" || BufferTempRev == "" || BufferTempIrev == "" || BufferTempMantel == "" || BufferTempMantel == "-" || BufferLaenge == "" ? "?" : parseFloat((BufferProzentRev.replace(/,/g, '.') / BufferTempRev.replace(/,/g, '.') * BufferTempIrev.replace(/,/g, '.') * 10 + BufferProzentIrev.replace(/,/g, '.') * 10) * BufferLaenge.replace(/,/g, '.') - parseFloat(BufferTempMantel.replace(/,/g, '.')) / 100 * parseFloat(BufferLaenge.replace(/,/g, '.'))).toFixed(2));

    const GesamtMatte = GesamtMatteCalc();

    const GesamtFuge = GesamtFugeCalc();;

    const KOMPRESSION_PROZENT_DYN = (100 - ((parseFloat(GesamtFuge.replace(/,/g, '.')) - Gesamtdehnung) / (parseFloat(GesamtMatte.replace(/,/g, '.')) / 100))).toFixed(2);



    return (
        <div className='outter_Box'>
            <div className='inner_Box'>
                <h2>Position</h2>
                <fieldset className='top_margin20'>
                    <legend>Position</legend>
                    <table className='w100 center_content'>
                        <tbody>
                            <tr>
                                <th className='w10'>Position</th>
                                <th>Titel</th>
                                <th className='w15'>Zeichnung Nr.</th>
                                <th className='w3' />
                                <th className='w25' />
                            </tr>
                            <tr>
                                <td ><input className='w90 center_text' value={BufferPositionNummer} onChange={(e) => { setBufferPositionNummer(e.target.value) }} /></td>
                                <td><input className='w80' value={BufferTitel!=null ? BufferTitel : ""} onChange={(e) => { setBufferTitel(e.target.value) }} /></td>
                                <td><input className='w93 center_text' value={BufferZeichnungsNummer} onChange={(e) => { setBufferZeichnungsNummer(e.target.value) }} /></td>
                                <td></td>
                                <td>
                                    <button className='pretty_button w90' onClick={DeletePosition}>Position Löschen</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <fieldset>
                    <legend>Materialdaten</legend>
                    <table className='bottom_margin25 w100'>
                        <tbody>
                            <tr>
                                <th className='w10'>
                                    <label >Qualität:</label>
                                </th>
                                <td className='w45'><input className='w100' value={BufferMaterialQualitaet} onChange={(e) => { setBufferMaterialQualitaet(e.target.value) }} /></td>
                                <td className='w5' />
                                <td className='w40'><button className='pretty_button w97' onClick={ToLoadData}>Materialdaten laden</button></td>
                            </tr>
                        </tbody>
                    </table>

                    <table className='w100 center_content'>
                        <tbody>
                            <tr>
                                <th className='w30'>Temperatur (revers.)
                                </th>
                                <th className='w6' />
                                <th className='w30' >Temperatur (irrevers.)
                                    <div className="tooltip">❓
                                        <span className="tooltiptext">Diese Temperatur wird als angenommene Temperatur für die Berechnung genutzt.</span>
                                    </div>
                                </th>
                                <th className='w34' />
                            </tr>
                            <tr>
                                <td><input className='w35 center_text' value={BufferTempRev} onChange={(e) => { changeBufferTempRev(e.target.value) }} />&deg;C
                                </td>
                                <td />
                                <td><input className='w35 center_text' value={BufferTempIrev} onChange={(e) => { changeBufferTempIrev(e.target.value) }} />&deg;C</td>
                                <td />
                            </tr>
                            <tr>
                                <th>Längenänderung (revers.)
                                    <div className="tooltip">❓
                                        <span className="tooltiptext">Wert der elastischen (umkehrbaren) Verformung bei Temperatur X</span>
                                    </div>
                                </th>
                                <th />
                                <th>Längenänderung (irrevers.)
                                    <div className="tooltip">❓
                                        <span className="tooltiptext">Wert der plastischen (bleibenden) Verformung bei Temperatur X</span>
                                    </div>
                                </th>
                                <th />
                            </tr>
                            <tr>
                                <td><input className='w35 center_text' value={BufferProzentRev} onChange={(e) => { changeBufferProzentRev(e.target.value) }} />%</td>
                                <td />
                                <td><input className='w35 center_text' value={BufferProzentIrev} onChange={(e) => { changeBufferProzentIrev(e.target.value) }} />%</td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </fieldset>



                <fieldset>
                    <legend>Berechnung</legend>
                    <p>Angenommene Temperatur = <label>{BufferTempIrev}</label>°C</p>
                    <p>Materialqualität = <label>{BufferMaterialQualitaet}</label></p>
                    <p>Reversible Änderung = {BufferProzentRev == "" || BufferProzentRev == null || BufferProzentRev == "-" ? "?" : parseFloat(BufferProzentRev.replace(/,/g, '.') / BufferTempRev.replace(/,/g, '.') * BufferTempIrev.replace(/,/g, '.')).toFixed(3).replace('.', ',')}% bei {BufferTempIrev}°C auf 1m</p>
                    <p>({BufferProzentRev == "" || BufferProzentRev == null || BufferProzentRev == "-" ? "?" : parseFloat(BufferProzentRev.replace(/,/g, '.') / BufferTempRev.replace(/,/g, '.') * BufferTempIrev.replace(/,/g, '.')).toFixed(3).replace('.', ',')}{BufferProzentIrev.replace(/,/g, '.') >= 0 ? "+" : ""}{BufferProzentIrev == "" || BufferProzentIrev == null || BufferProzentIrev == "-" ? "?" : parseFloat(BufferProzentIrev.replace(/,/g, '.')).toFixed(3).replace('.', ',')}) = {BufferProzentRev == "-" || BufferProzentIrev == "-" || BufferProzentRev == "" || BufferProzentIrev == "" || BufferTempRev == "" || BufferTempIrev == "" ? "?" : parseFloat(BufferProzentRev.replace(/,/g, '.') / BufferTempRev.replace(/,/g, '.') * BufferTempIrev.replace(/,/g, '.') * 10 + BufferProzentIrev.replace(/,/g, '.') * 10).toFixed(2).replace('.', ',')}mm bei {BufferTempIrev}°C auf 1m</p>
                </fieldset>
                <fieldset>
                    <legend>Weitere Informationen</legend>
                    <table className='w50'>
                        <tbody>
                            <tr>
                                <td className='w1_5' />
                                <th className='w18_5 align_left'>{BufferIsRadial == false ? "Länge:" : "Umfang:"}</th>
                                <td className='w30'><input className='w75 center_text' value={BufferLaenge} onChange={(e) => { changeBufferLaenge(e.target.value) }} />m</td>
                                <td className='w30'><input type="checkbox" checked={BufferIsRadial} onChange={(e) => { setBufferIsRadial(e.target.checked) }} /> Radial
                                    <b>
                                        <div className="tooltip">❓
                                            <span className="tooltiptext">Dehnung im Ring (Bogenmaß)</span>
                                        </div>
                                    </b>
                                </td>
                                <td className='w20' />
                            </tr>
                        </tbody>
                    </table>
                    <table className='w50'>
                        <tbody>
                            <tr>
                                <th className='w50'>Temperatur Stahlmantel</th>
                                <td className='w30'><input className='w75 center_text' value={BufferTempMantel} onChange={(e) => { changeBufferTempMantel(e.target.value) }} />&deg;C</td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </fieldset>

                <fieldset>
                    <legend>Dehnfuge</legend>
                    <p>Theoretisch benötigt: <label />{Gesamtdehnung.toString().replace('.', ',') }mm (inkl. {BufferTempMantel == "" || BufferTempMantel == "-" || BufferLaenge == "" ? "?" : parseFloat(BufferTempMantel.replace(/,/g, '.') / 100 * parseFloat(BufferLaenge.replace(/,/g, '.'))).toFixed(2).toString().replace('.', ',') }mm für die Dehnung im Stahlbau) </p>
                    
                    <table>
                        <tbody>
                            <tr>
                                <td className='w10'><input className='w66 center_text' value={BufferQuantitaetFugeA} onChange={(e) => { changeBufferQuantitaetFugeA(e.target.value) }} /> ✖ </td>
                                <td className='w15'><input className='w70 center_text' value={BufferFugeA} onChange={(e) => { changeBufferFugeA(e.target.value) }} />mm</td>
                                <td className='w9'> Fuge mit </td>
                                <td className='w15'><input className='w70 center_text' value={BufferMatteA} onChange={(e) => { changeBufferMatteA(e.target.value) }} />mm</td>
                                <td> Faser ausfüllen</td>
                                <td />
                            </tr>
                            <tr>
                                <td><input className='w66 center_text' value={BufferQuantitaetFugeB} onChange={(e) => { changeBufferQuantitaetFugeB(e.target.value) }} /> ✖ </td>
                                <td><input className='w70 center_text' value={BufferFugeB} onChange={(e) => { changeBufferFugeB(e.target.value) }} />mm</td>
                                <td> Fuge mit </td>
                                <td><input className='w70 center_text' value={BufferMatteB} onChange={(e) => { changeBufferMatteB(e.target.value) }} />mm</td>
                                <td> Faser ausfüllen</td>
                                <td />
                            </tr>
                            <tr>
                                <td><input className='w66 center_text' value={BufferQuantitaetFugeC} onChange={(e) => { changeBufferQuantitaetFugeC(e.target.value) }} /> ✖ </td>
                                <td><input className='w70 center_text' value={BufferFugeC} onChange={(e) => { changeBufferFugeC(e.target.value) }} />mm</td>
                                <td> Fuge mit </td>
                                <td><input className='w70 center_text' value={BufferMatteC} onChange={(e) => { changeBufferMatteC(e.target.value) }} />mm</td>
                                <td> Faser ausfüllen</td>
                                <td />
                            </tr>
                            <tr>
                                <td className='align_right'><b>Gesamt:</b></td>
                                <td className='align_center'>
                                    <table className='w100'>
                                        <tbody>
                                            <tr>
                                                <td className='w70'><b>{GesamtFuge}</b></td>
                                                <td className='w30' />
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td className='align_right'><b>Gesamt:</b></td>
                                <td className='align_center'>
                                    <table className='w100'>
                                        <tbody>
                                            <tr>
                                                <td className='w70'> <b>{GesamtMatte}</b></td>
                                                <td className='w30' />
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td />
                                <td />
                            </tr>
                            <tr>
                                <td className='align_right'><b>Kompr.:</b></td>
                                <td className='align_center'>
                                    <table className='w100'>
                                        <tbody>
                                            <tr>
                                                <td className='w70'><b>{GesamtFuge != "0,00" && GesamtMatte != "0,00"?  KOMPRESSION_PROZENT_DYN.toString().replace('.', ',') : "?"}%</b></td>
                                                <td className='w30' />
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td className='align_right'></td>
                                <td className='align_center'>
                                    
                                </td>
                                <td />
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <button className='centered_Button w50 top_margin10 bottom_margin10 pretty_button' onClick={BackToProjectPage} >Zurück zur Projektübersicht</button>
            </div>
        </div>
    );
};
export default EditPage;