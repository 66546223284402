import create from 'zustand';


const useDehnfugenState = create((set,get) => ({
  setState : (newstate ) => set(state => newstate),
  Projekt: null,
  setProjekt: (wert) => set(state => ({ Projekt: wert })),

  Position: null,
  setPosition: (wert) => set(state => ({ Position: wert })),

  ExportInGerman: true,
  setExportInGerman: (wert) => set(state => ({ ExportInGerman: wert })),

  BufferTitel: null,
  setBufferTitel: (wert) => set(state => ({ BufferTitel: wert })),
  BufferPositionNummer: "",
  setBufferPositionNummer: (wert) => set(state => ({ BufferPositionNummer: wert })),
  BufferZeichnungsNummer: "",
  setBufferZeichnungsNummer: (wert) => set(state => ({ BufferZeichnungsNummer: wert })),
  BufferMaterialQualitaet: "",
  setBufferMaterialQualitaet: (wert) => set(state => ({ BufferMaterialQualitaet: wert })),
  BufferTempRev: "",
  setBufferTempRev: (wert) => set(state => ({ BufferTempRev: wert })),
  BufferTempIrev: "",
  setBufferTempIrev: (wert) => set(state => ({ BufferTempIrev: wert })),
  BufferProzentRev: "",
  setBufferProzentRev: (wert) => set(state => ({ BufferProzentRev: wert })),
  BufferProzentIrev: "",
  setBufferProzentIrev: (wert) => set(state => ({ BufferProzentIrev: wert })),
  BufferTempMantel: "",
  setBufferTempMantel: (wert) => set(state => ({ BufferTempMantel: wert })),
  BufferLaenge: "",
  setBufferLaenge: (wert) => set(state => ({ BufferLaenge: wert })),
  BufferIsRadial: "",
  setBufferIsRadial: (wert) => set(state => ({ BufferIsRadial: wert })),
  BufferQuantitaetFugeA: "",
  setBufferQuantitaetFugeA: (wert) => set(state => ({ BufferQuantitaetFugeA: wert })),
  BufferQuantitaetFugeB: "",
  setBufferQuantitaetFugeB: (wert) => set(state => ({ BufferQuantitaetFugeB: wert })),
  BufferQuantitaetFugeC: "",
  setBufferQuantitaetFugeC: (wert) => set(state => ({ BufferQuantitaetFugeC: wert })),
  BufferFugeA: "",
  setBufferFugeA: (wert) => set(state => ({ BufferFugeA: wert })),
  BufferFugeB: "",
  setBufferFugeB: (wert) => set(state => ({ BufferFugeB: wert })),
  BufferFugeC: "",
  setBufferFugeC: (wert) => set(state => ({ BufferFugeC: wert })),
  BufferMatteA: "",
  setBufferMatteA: (wert) => set(state => ({ BufferMatteA: wert })),
  BufferMatteB: "",
  setBufferMatteB: (wert) => set(state => ({ BufferMatteB: wert })),
  BufferMatteC: "",
  setBufferMatteC: (wert) => set(state => ({ BufferMatteC: wert })),


  BufferProjektitel: "",
  setBufferProjektitel: (wert) => set(state => ({ BufferProjektitel: wert })),
  BufferProjektnummer: "",
  setBufferProjektnummer: (wert) => set(state => ({ BufferProjektnummer: wert })),

  BufferOpportunityNummer: "",
  setBufferOpportunityNummer: (wert) => set(state => ({ BufferOpportunityNummer: wert })),
  BufferKunde: "",
  setBufferKunde: (wert) => set(state => ({ BufferKunde: wert })),

  BufferRevision: "",
  setBufferRevision: (wert) => set(state => ({ BufferRevision: wert })),

  selectedGruensteinID: null,
  setselectedGruensteinID: (wert) => set(state => ({ selectedGruensteinID: wert })),

  RevData: [],
  setRevData: (wert) => set(state => ({ RevData: wert })),
  IrrevData: [],
  setIrrevData: (wert) => set(state => ({ IrrevData: wert })),


  Gruenliste: [],
  setGruenliste: (wert) => set(state => ({ Gruenliste: wert })),

}));

export default useDehnfugenState;