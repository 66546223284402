import useDehnfugenState from './state/dehnfugen';
import { useNavigate } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import useAPICall from './APICall';
import './dehnfugen.css';

const LoadDataPage = () => {
    const history = useNavigate();

    const BufferMaterialQualitaet = useDehnfugenState(state => state.BufferMaterialQualitaet);
    const setBufferMaterialQualitaet = useDehnfugenState(state => state.setBufferMaterialQualitaet)
    const BufferTempRev = useDehnfugenState(state => state.BufferTempRev);
    const setBufferTempRev = useDehnfugenState(state => state.setBufferTempRev)
    const BufferTempIrev = useDehnfugenState(state => state.BufferTempIrev);
    const setBufferTempIrev = useDehnfugenState(state => state.setBufferTempIrev)
    const BufferProzentRev = useDehnfugenState(state => state.BufferProzentRev);
    const setBufferProzentRev = useDehnfugenState(state => state.setBufferProzentRev)
    const BufferProzentIrev = useDehnfugenState(state => state.BufferProzentIrev);
    const setBufferProzentIrev = useDehnfugenState(state => state.setBufferProzentIrev)
    const Position = useDehnfugenState(state => state.Position);
    const selectedGruensteinID = useDehnfugenState(state => state.selectedGruensteinID)
    const setselectedGruensteinID = useDehnfugenState(state => state.setselectedGruensteinID);

    const Projekt = useDehnfugenState(state => state.Projekt);

    const RevData = useDehnfugenState(state => state.RevData)
    const setRevData = useDehnfugenState(state => state.setRevData);
    const IrrevData = useDehnfugenState(state => state.IrrevData)
    const setIrrevData = useDehnfugenState(state => state.setIrrevData);

    const Gruenliste = useDehnfugenState(state => state.Gruenliste);

    const rawRevDaten = useAPICall("/api/v2/ProductData/WaermeDehnung/" + selectedGruensteinID?.toString());
    const rawIrrevDaten = useAPICall("/api/v2/ProductData/IrrevLaengenaenderungen/" + selectedGruensteinID?.toString());

    const [last_rev_id, set_last_rev_id] = useState(null)
    const [last_irrev_id, set_last_irrev_id] = useState(null)

    //Variable die beim ersten Aufruf undefined ist und dann bei Änderung des GruensteinID's auf true gesetzt wird. -> Damit beim ersten öffnen nicht die werte gecleared werden!
    const [clear_yet, set_clear_yet] = useState(null)

    useEffect(() => {
        GruensteinChange(BufferMaterialQualitaet, true);
    }, []);


    useEffect(() => {
        set_last_rev_id(-1);
        set_last_irrev_id(-1);
        if (Position == null) {
            history("/project")
        }
    }, [Position]);


    useEffect(() => {
        if (rawRevDaten.loading == false) {
            FormatRev(rawRevDaten.data);
        }
        function FormatRev(daten) {

            let arr = [];
            if (daten.length == 0 || !selectedGruensteinID) {
                arr.push({ temp: 0, prozent: 0, text: "Keine Daten" });
            }
            else {
                arr.push({ temp: 0, prozent: 0, text: "Daten vorhanden" });
                for (let i = 0; i < daten.length; i++) {
                    let text_temp = daten[i].soll.toString() + "% bei " + daten[i].merkmal.replace(/\D/g, "") + "°C";
                    arr.push({ temp: parseInt(daten[i].merkmal.replace(/\D/g, "")), prozent: daten[i].soll, text: text_temp.replace(/\./g, ',') });
                }
            }
            if (last_rev_id != selectedGruensteinID || RevData.length != arr.length) {

                set_last_rev_id(selectedGruensteinID);
                setRevData(arr);
            }
        }
    }, [rawRevDaten.loading, selectedGruensteinID]);

    useEffect(() => {
        if (rawIrrevDaten.loading == false) {
            FormatIrrev(rawIrrevDaten.data);
        }
        function FormatIrrev(daten_i) {
            let lis = [];
            if (daten_i.length == 0 || !selectedGruensteinID) {
                lis.push({ temp: 0, prozent: 0, text: "Keine Daten" });
            }
            else {
                lis.push({ temp: 0, prozent: 0, text: "Daten vorhanden" });
                //loop fails bei REFRACORUND 95 ED
                for (let j = 0; j < daten_i.length; j++) {
                    let text_irev_temp = daten_i[j].soll.toString().replace(/\./g, ',') + "% bei " + daten_i[j].merkmal.replace(/\D/g, "") + "°C";
                    lis.push({ temp: parseInt(daten_i[j].merkmal.replace(/\D/g, "")), prozent: daten_i[j].soll, text: text_irev_temp.replace(/\./g, ',') });
                }
            }
            if (last_irrev_id != selectedGruensteinID || IrrevData.length != lis.length) {
                set_last_irrev_id(selectedGruensteinID);
                setIrrevData(lis);
            }
        }
    }, [rawIrrevDaten.loading, selectedGruensteinID]);

    useEffect(() => {
        if (clear_yet) {
            setBufferTempRev("");
            setBufferProzentRev("");

            setBufferTempIrev("");
            setBufferProzentIrev("");
        }
        else {
            set_clear_yet(true);
        }
    }, [selectedGruensteinID]);

    function BackToEdit() {
        history("/edit")
    }



    const GruenlisteDisplay = (Gruenliste != null) ? Gruenliste.map((element, i) => {
        return <option key={element.gruenName+i.toString()} value={element.gruenName} id={element.gruenNr} />;
        //ANHAND VON ID LADEN?!
    }) : [];

    function GruensteinChange(value, dont_clear = false) {
        setBufferMaterialQualitaet(value);

        if (dont_clear == false) {
            setBufferTempRev("");
            setBufferTempIrev("");
            setBufferProzentRev("");
            setBufferProzentIrev("");
        }

        let SelectedGruen = Gruenliste.find(element => element.gruenName == value);
        if (SelectedGruen != undefined) {
            setselectedGruensteinID(SelectedGruen.gruenNr);

            ///////////CODE HIER FÜR UPDATE
        }
        else {
            setselectedGruensteinID(null)
        }
    }

    function changeBufferTempRev(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferTempRev(val);
        }
    }

    function changeBufferTempIrev(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false) {
            setBufferTempIrev(val);
        }
    }
    function changeBufferProzentRev(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false || val == "-") {
            setBufferProzentRev(val);
        }
    }
    function changeBufferProzentIrev(val) {
        var newVal = val.replace(/,/g, '.');
        if (isNaN(newVal) == false || val == "-") {
            setBufferProzentIrev(val);
        }
    }

    function RevSelectionChanged(wert) {

        if (wert != 0) {
            let SelectedRevWert = RevData.find(element => element.temp == wert);

            if (!SelectedRevWert) {
                return;
            }

            setBufferTempRev(SelectedRevWert.temp.toString());
            setBufferProzentRev(SelectedRevWert.prozent.toString().replace(/\./g, ','));
        }
    }
    function IrrevSelectionChanged(wert) {

        if (wert != 0) {
            let SelectedIrrevWert = IrrevData.find(element => element.temp == wert);

            if (!SelectedIrrevWert) {
                return;
            }


            setBufferTempIrev(SelectedIrrevWert.temp.toString());
            setBufferProzentIrev(SelectedIrrevWert.prozent.toString().replace(/\./g, ','));
        }
    }

    const RevDataDisplay = (Projekt != null) ? RevData.map((position, i) => {
        return <option key={i} value={position.temp}>{position.text}</option>;
    }) : [];

    const IrrevDataDisplay = (Projekt != null) ? IrrevData.map((position, i) => {
        return <option key={(i*-1)-1} value={position.temp}>{position.text}</option>;
    }) : [];

    return (
        <div className='outter_Box'>
            <div className='inner_Box_small'>
                <h2>Daten abrufen</h2>
                <fieldset className='top_margin20'>
                    <legend>Qualität</legend>






                    <table className='w100 center_content'>
                        <tbody>
                            <tr>
                                <th className='w10'>
                                    <label htmlFor="gruenliste">Qualität: </label>
                                </th>
                                <th className='w90'>
                                    <input className='w95' list="gruenliste" id="myBrowser" name="myBrowser" value={BufferMaterialQualitaet} onChange={(e) => { GruensteinChange(e.target.value) }} />
                                    <datalist id="gruenliste">
                                        {GruenlisteDisplay}
                                    </datalist>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <fieldset>
                    <legend>Messdaten</legend>
                    <table className='w100 center_content'>
                        <tbody>
                            <tr>
                                <th>Geladen (revers.)</th>
                                <th>Geladen (irrevers.)</th>
                            </tr>
                            <tr>
                                <td>
                                    <select className='w75' disabled={!selectedGruensteinID} onChange={(e) => { RevSelectionChanged(e.target.value) }}>
                                        {RevDataDisplay}
                                    </select>
                                </td>
                                <td>
                                    <select className='w75' disabled={!selectedGruensteinID} onChange={(e) => { IrrevSelectionChanged(e.target.value) }}>
                                        {IrrevDataDisplay}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Temperatur (revers.)</th>
                                <th>Temperatur (irrevers.)</th>
                            </tr>
                            <tr>
                                <td><input className='w33 center_text' value={BufferTempRev} onChange={(e) => { changeBufferTempRev(e.target.value) }} />°C</td>
                                <td><input className='w33 center_text' value={BufferTempIrev} onChange={(e) => { changeBufferTempIrev(e.target.value) }} />°C</td>
                            </tr>
                            <tr>
                                <th>Längenänderung (revers.)</th>
                                <th>Längenänderung (irrevers.)</th>
                            </tr>
                            <tr>
                                <td><input className='w33 center_text' value={BufferProzentRev} onChange={(e) => { changeBufferProzentRev(e.target.value) }} />%</td>
                                <td><input className='w33 center_text' value={BufferProzentIrev} onChange={(e) => { changeBufferProzentIrev(e.target.value) }} />%</td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <button className='centered_Button pretty_button w50 top_margin10 bottom_margin10' onClick={BackToEdit}>Zurück zur Position</button>

            </div>
        </div>
    );
};
export default LoadDataPage;