import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes
} from "react-router-dom";
import StartPage from './start.js'
import ProjectPage from './project.js'
import EditPage from './edit.js'
import LoadDataPage from "./load_data.js";



export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/edit" element={<EditPage />} /> //DAS HIER LÄUFT!
        <Route path="/project" element={<ProjectPage />} />
        <Route path="/edit/loaddata" element={<LoadDataPage />} />
      </Routes>


    </Router>



  );
}


